import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Layout, Wrapper, SliceZone, Title, SEO } from '../components'
import website from '../../config/website'

import { theme } from '../styles'

const Hero = styled.header`
  background-color: ${props => props.theme.colors.greyLight};
  padding-top: 1rem;
  padding-bottom: 1em;
`

const FeaturedImage = styled.div`
    display: grid;
    justify-items: center;

    img {
    width: 100%;
    }

    @media screen and (min-width: 1500px) {
    margin-bottom: 5em;
    padding-right: 4em;
    img {
    width: 60%;
    }
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`

const PostWrapper = Wrapper.withComponent('main')

const Post = ({ data: { prismicPost, posts }, location }) => {
  const { data } = prismicPost
  return (
      <Layout customSEO>
          <SEO
              title={`${data.title.text} | ${website.titleAlt}`}
              pathname={location.pathname}
              desc={data.brief.text}
              node={prismicPost}
              article
          />
          <FeaturedImage>
              <img src={data.featured_image.url} alt={data.featured_image.alt} />
          </FeaturedImage>
          <Hero>
              <Wrapper>
                  <h1>{data.title.text}</h1>
                  <Headline>
                      {' \u2022 '} {data.date} {' \u2022 '}
                  </Headline>
              </Wrapper>
          </Hero>
          <PostWrapper id={website.skipNavId}>
              <SliceZone allSlices={data.body} />
          </PostWrapper>
      </Layout>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        brief {
          text
        }
        featured_image {
          url
          alt
        }
        date(formatString: "DD.MM.YYYY")
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 2, sort: { fields: [data___date], order: DESC }, filter: { uid: { ne: $uid } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          date(formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`
